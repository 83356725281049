<template>
    <!-- 预约专家 -->
    <div class="reservationbox" :class="layerclass" v-if="reservationshow">
        <div class="resbox" :class="layerboxclass">
            <div class="resform">
                <div class="title">预约专家</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm" v-if="successstatus===0">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="ruleForm.name" maxlength="8"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="tel">
                        <el-input v-model="ruleForm.tel" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="短信验证码" prop="code">
                        <el-input v-model="ruleForm.code" maxlength="6" class="code"></el-input>
                        <div class="codebtn" v-if="isSend">{{time}} s</div>
                        <div class="codebtn" v-else @click="sendcode()">发送验证码</div>
                    </el-form-item>
                    <div class="yuyuebox">
                        <el-button type="primary" @click="submitForm('ruleForm')">立即预约</el-button>
                    </div>
                </el-form>
                <div class="success" v-if="successstatus===1">
                    <i class="iconfont success">&#xe607;</i>
                    <div class="successtxt">恭喜您，预约成功！</div>
                    <div class="marktxt"><span class="t">{{successtime}}</span> s 后自动关闭窗口</div>
                </div>
                <div class="back" @click.prevent="reservationback()">返回</div>
            </div>
        </div>

        <!-- 拼图滑动验证 -->
        <PuzzleVerification ref="verificationShow" :Mobile="ruleForm.tel" :onSuccess="slideVerifySuccess"></PuzzleVerification>
    </div>
</template>
<script>
import PuzzleVerification from "@/components/PuzzleVerification.vue";
import {Message} from 'element-ui';
import API from '@/api/config';
export default{
    props: {
        reservationid: String
    },
    components:{
        PuzzleVerification
    },
    data(){
        var validatetel = (rule, value, callback) => {
            let str =  /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
            if(str.test(this.ruleForm.tel.trim())==false){
                callback(new Error('手机号格式不正确！'));
            }
            else {
                callback();
            }
        };
        return{
            reservationshow:false,
            layerclass:'',
            layerboxclass:'',
            isSend:false,
            time: 60,
			timer: null,
            successstatus:0,
            successtime:5,
            successtimer:null,
            ruleForm: {
                name: '',
                tel:'',
                code: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: validatetel, trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' }
                ],
            }
        }
    },
    mounted(){
        
    },
    methods:{
        //发送验证码
		sendcode(){
            if (!this.ruleForm.tel.trim()) {
                Message({message:"请输入手机号！",type:'error'});return false;
            }
            else{
                let str =  /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if(str.test(this.ruleForm.tel.trim())==false){
                    Message({message:"手机号格式不正确！",type:'error'});return false;
                }
            }
            this.$refs.verificationShow.isVerificationShow=true;//显示 拼图滑动验证组件
            this.$refs.verificationShow.refreshImg();//刷新 拼图滑动验证组件
		},
        //拼图滑动验证成功的回调
        slideVerifySuccess(par) {
            this.$refs.verificationShow.isVerificationShow=false;//隐藏拼图滑动验证组件
            // 发送手机验证码
            par.ValidateType = 5;
            API.Web.SendValidateCode(par).then(res => {
                if(res.Code===0){
                    this.handleTimer();//倒计时
                }
                else{
                    Message({message:res.Message,type:'error'});return false;
                }
            });
        },
        //验证码倒计时
		handleTimer(){
			this.isSend = true;
			this.timer = setInterval(() => {
				if (this.time > 0) {
					this.time--;
				}
				else {
					clearInterval(this.timer);
					this.isSend = false;
					this.time = 60;
				}
			}, 1000);
		},
        //预约
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    API.Web.TeacherReserve({TeacherId:this.reservationid,Name:this.ruleForm.name,Mobile:this.ruleForm.tel,TerminalType: 0,ValidateType:5,ValidateCode:this.ruleForm.code}).then(res => {
                        if(res.Code===0){
                            this.$refs[formName].resetFields();
                            this.successstatus=1;
                            this.successhandleTimer();//预约成功
                        }else{
                            Message({message:res.Message,type:'error'});
                        }
                    });
                }
            });
        },
        //预约成功 关闭倒计时
		successhandleTimer(){
			this.successtimer = setInterval(() => {
				if (this.successtime > 1) {
					this.successtime--;
				}
				else {
					clearInterval(this.successtimer);
                    this.reservationback();
                    this.successtime=1;
				}
			}, 1000);
		},
        //返回
        reservationback(){
            this.layerclass='layerhide';
            this.layerboxclass="layerboxhide";
            this.successtime=5;
            if(this.successtimer!=null){
                clearInterval(this.successtimer);
            }
            let st = setTimeout(()=>{
                this.reservationshow=false;
                this.successstatus=0;
                clearTimeout(st);
            },300);
        }
    }
}
</script>
<style lang="less" scoped>
.reservationbox.layershow{
    animation:layershow 0.3s ease 0s 1;
    -webkit-animation:layershow 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    @keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
}
.reservationbox.layerhide{
    animation:layerhide 0.3s ease 0s 1;
    -webkit-animation:layerhide 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    @keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
}
.reservationbox{
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    background-color: rgba(0,0,0,0.5);
    .resbox.layerboxshow{
        animation:layerboxshow 0.3s ease 0s 1;
        -webkit-animation:layerboxshow 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxshow {
            from {top: calc(50% - 280px);}
            to {top: calc(50% - 230px);}
        }
        @keyframes layerboxshow {
            from {top: calc(50% - 280px);}
            to {top: calc(50% - 230px);}
        }
    }
    .resbox.layerboxhide{
        animation:layerboxhide 0.3s ease 0s 1;
        -webkit-animation:layerboxhide 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxhide {
            from {top: calc(50% - 230px);}
            to {top: calc(50% - 280px);}
        }
        @keyframes layerboxhide {
            from {top: calc(50% - 230px);}
            to {top: calc(50% - 280px)}
        }
    }
    .resbox{
        width: 460px;
        height: 460px;
        position: absolute;
        top: calc(50% - 280px);
        left: calc(50% - 230px);
        background-color: #fff;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        .resform{
            width: 100%;
            height: 100%;
            position: relative;
            .title{
                font-size: 24px;
                color: #555;
                text-align: center;
                padding: 20px 0 20px;
                border-bottom: 1px solid #e6e6e6;
                margin-bottom: 20px;
            }
            .code{
                width: 60%;
            }
            .codebtn{
                width: 100px;
                height: calc(100% - 2px);
                position: absolute;
                right: 0px;
                top: 0px;
                line-height: 40px;
                text-align: center;
                color: #666;
                font-size: 14px;
                border: 1px solid #d8d8d8;
                user-select: none;
                border-radius: 3px;
            }
            .codebtn:hover{
                cursor: pointer;
                color: rgb(var(--themecolor));
            }
            .yuyuebox{
                width: 342px;
                margin: auto;
                margin-top: 50px;
            }
            .back{
                width: 100%;
                height: 44px;
                line-height: 44px;
                position: absolute;
                bottom: 0;
                left: 0px;
                z-index: 66;
                background-color: #ddd;
                color: #666;
                font-size: 14px;
                letter-spacing: 2px;
                text-align: center;
                user-select: none;
            }
            .back:hover{
                cursor: pointer;
                opacity: 0.85;
                color: rgb(var(--themecolor));
            }
            .success{
                width: 100%;
                i.success{
                    color: #159405;
                    font-size: 100px;
                    display: block;
                    text-align: center;
                    padding-top: 20px;
                }
                .successtxt{
                    color: #666;
                    font-size: 18px;
                    text-align: center;
                    margin-top: 10px;
                }
                .marktxt{
                    color: #888;
                    font-size: 14px;
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
    }
}
</style>
<style lang="less">
.reservationbox .resbox .el-form .el-form-item{
    max-width: 86%;
    min-width: 400px;
}
.reservationbox .resbox .yuyuebox .el-button--primary{
    width: 100%;
    margin: 0px;
    background-color: rgba(var(--themecolor),0.85);
    border-color: rgba(var(--themecolor),0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}
.reservationbox .resbox .yuyuebox .el-button--primary:hover{
    background-color: rgba(var(--themecolor),1);
    border-color: rgba(var(--themecolor),1);
}
.reservationbox .resbox .yuyuebox .el-form-item.is-error .el-input__inner{
    background-color: rgba(var(--themecolor),0.75);
    border-color: rgba(var(--themecolor),0.75);
}
.reservationbox .el-form-item .el-input__inner:focus{
    border:1px solid rgba(var(--themecolor),0.75);
}
</style>